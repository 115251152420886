const isArrayOfStrings = (arr: unknown): arr is string[] =>
  Array.isArray(arr) && arr.every((item) => typeof item === 'string')

const validFormError = (error: unknown): error is string | string[] =>
  typeof error === 'string' || isArrayOfStrings(error)

// Callback function for ajax forms that renders an HTML error box from pre-rendered HTML (shared/_form_errors.html.erb) or from an array of error messages
// Prepends the errors to the top of the form / specified container (removes existing errors first if present)
const renderFormErrors = (errors: string | string[] | null, targetContainer: HTMLElement): void => {
  targetContainer.querySelector('.form-errors')?.remove()

  // A custom title for the errors can be specified as a data-attribute when the default "Saving failed" message isn't appropriate
  const customErrorTitle = targetContainer.dataset.customErrorTitle

  let errorHTML = ''

  if (typeof errors === 'string' && errors.trimStart().startsWith('<div class="form-errors">')) {
    // Pre-rendered HTML from the server should start with `<div class="form-errors">`
    errorHTML = errors
  } else {
    // `errors` can also be an array of error messages
    // Other responses are most likely uncaught server errors that shouldn't be shown to users
    errors = isArrayOfStrings(errors) && !!errors.length ? errors : [window.I18n.unknown_error as string]
    errorHTML = `
      <div class="form-errors">
        <h2>${customErrorTitle ?? window.I18n.saving_failed}</h2>
        <ul>
          ${errors.map((error) => `<li>${error}</li>`).join('\n')}
        </ul>
      </div>
    `
  }

  targetContainer.insertAdjacentHTML('afterbegin', errorHTML)
  targetContainer.scrollIntoView({ behavior: 'smooth' })
}

export { renderFormErrors, validFormError }
